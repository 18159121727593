
import './index.less';
import {
    defineComponent,
    watch,
    computed,
    ref,
    PropType
} from 'vue';

export default defineComponent({
    props: {
        total: {
            required: true,
            type: Number
        },
        reset: {
            required: true,
            type: Number
        },
        initRow: {
            default: 10,
            type: [Number, String]
        },
        initPage: {
            default: 1,
            type: [Number, String]
        },
        backFirstPage: {
            default: 1,
            type: Number
        },
        rowOptions: {
            type: Array as PropType<number[]>,
            default: () => [10, 20, 30, 40]
        }
    },
    setup(props, { emit }) {
        // 当前第几页
        const currentPage = ref(Number(props.initPage));
        watch(computed(() => props.backFirstPage), () => {
            currentPage.value = 1;
        });
        // 页码输入参数
        const pageInput = ref(1);
        // 页数
        const pageTotalCount = ref(0);
        // update
        const update = ref(0);

        // 每页条数
        const row = ref(Number(props.initRow));

        const search = () => {
            update.value += 1;
        };

        watch(computed(() => props.reset), () => {
            currentPage.value = 1;
            pageInput.value = 1;
            pageTotalCount.value = 0;
            row.value = 10;
            search();
        });

        const changeRow = (value: number) => {
            row.value = value;
            search();
        };

        const setPage = () => {
            if (Number(pageInput.value) > pageTotalCount.value) {
                pageInput.value = pageTotalCount.value;
            }

            currentPage.value = Number(pageInput.value);
        };

        const changeCurrentPage = (page: number) => {
            currentPage.value = page;
            pageInput.value = page;
            search();
        };

        const keyEnter = (e: {keyCode: number}) => {
            if (e.keyCode === 13) {
                setPage();
            }
        };

        // 无法直接将computed赋值给pageTotalCount
        const computedPageCount = computed(() => Math.ceil(props.total / row.value));
        watch(computedPageCount, () => {
            pageTotalCount.value = computedPageCount.value;
        });

        watch(update, () => {
            emit('search', row.value, currentPage.value);
        }, {
            immediate: true
        });

        return {
            currentPage,
            row,
            pageInput,
            changeCurrentPage,
            changeRow,
            setPage,
            keyEnter,
            pageTotalCount
        };
    }
});
