import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")
  const _component_pagination = _resolveComponent("pagination")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_list, {
      headers: _ctx.headers,
      listData: _ctx.listData,
      requestData: _ctx.requestDataWithRow,
      action: _ctx.action,
      showCheck: _ctx.showCheck,
      selectedItem: _ctx.selectedItem,
      keyOfCheck: _ctx.keyOfCheck,
      update: _ctx.update + _ctx.updateFromPage,
      deleteUrl: _ctx.deleteUrl,
      keyOfDelete: _ctx.keyOfDelete,
      deleteMessage: _ctx.deleteMessage,
      deleteMultipleUrl: _ctx.deleteMultipleUrl,
      deleteMultipleMessage: _ctx.deleteMultipleMessage,
      deleteMultipleKeyType: _ctx.deleteMultipleKeyType,
      showDefaultAction: _ctx.showDefaultAction,
      saveParamInPath: _ctx.saveParamInPath || _ctx.saveParamInPathInRow,
      "disable-check-condition": _ctx.disableCheckCondition,
      onActionEmit: _ctx.actionEmit,
      onGetTotal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.totalCount = $event))
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.headers, (header, headerIndex) => {
        return {
          name: `${_ctx.getSlotName(header.name)}Header`,
          fn: _withCtx((data) => [
            (header.headerType === 'customize')
              ? _renderSlot(_ctx.$slots, `${_ctx.getSlotName(header.name)}Header`, {
                  key: 0,
                  scope: data.scope
                })
              : _createCommentVNode("", true)
          ])
        }
      }),
      _renderList(_ctx.headers, (header, headerIndex) => {
        return {
          name: _ctx.getSlotName(header.name),
          fn: _withCtx((data) => [
            (header.type === 'customize')
              ? _renderSlot(_ctx.$slots, _ctx.getSlotName(header.name), {
                  key: 0,
                  scope: data.scope
                })
              : _createCommentVNode("", true)
          ])
        }
      })
    ]), 1032, ["headers", "listData", "requestData", "action", "showCheck", "selectedItem", "keyOfCheck", "update", "deleteUrl", "keyOfDelete", "deleteMessage", "deleteMultipleUrl", "deleteMultipleMessage", "deleteMultipleKeyType", "showDefaultAction", "saveParamInPath", "disable-check-condition", "onActionEmit"]),
    _createVNode(_component_pagination, {
      total: _ctx.totalCount,
      onSearch: _ctx.setRow,
      reset: _ctx.resetPagination,
      backFirstPage: _ctx.backFirstPage,
      initPage: _ctx.paginatParam.page,
      initRow: _ctx.paginatParam.row
    }, null, 8, ["total", "onSearch", "reset", "backFirstPage", "initPage", "initRow"])
  ]))
}