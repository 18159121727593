
import {
    computed,
    defineComponent, inject, ref, Ref, watch
} from 'vue';
import pagination from '@/components/common/customize-pagination/index';
import list from './list.vue';
import listBaseProps from '../util/list-base-prop';
import { RequestData } from '../util/all-interface';
import { getSlotName, isNotUseRequestData } from '../util/util';
import { getSearch } from '../util/search';

export default defineComponent({
    components: {
        list,
        pagination
    },
    props: { ...listBaseProps, isSaveParamInPathInRow: { type: Boolean, default: false } },
    setup(props, { emit }) {
        const updateFromPage = ref(0);
        const totalCount = ref(0);
        const resetPagination = ref(0);
        // 初始化row, page参数
        const paginatParam = ref({
            row: '10',
            page: '1'
        });
        paginatParam.value = getSearch(paginatParam.value);

        // 保存搜索结果的row,page参数
        let rowValue = 0;
        let pageValue = 1;
        // 赋值row page 参数
        const requestDataWithRow: Ref<RequestData | false> = ref(false);
        watch(computed(() => props.requestData), () => {
            resetPagination.value += 1;
        });

        const backFirstPage = ref(0);
        watch(computed(() => props.update), () => {
            pageValue = 1;
            // 为了实现编辑department不返回第一页的临时方案
            console.log((props.requestData as RequestData).url);
            if ((props.requestData as RequestData).url === 'v3/web/community/user/getRoomListByNewPm'
             || (props.requestData as RequestData).url === 'v3/web/office/department/getDepartmentList') {
                pageValue = JSON.parse(JSON.stringify((props.requestData as RequestData).param.page as number));
            } else {
                backFirstPage.value += 1;
            }
            // backFirstPage.value += 1; //为了实现编辑department不返回第一页，移动到else
            if (isNotUseRequestData(props.requestData)) {
                requestDataWithRow.value = false;
            } else {
                requestDataWithRow.value = {
                    url: props.requestData.url,
                    param: Object.assign(props.requestData.param, {
                        row: rowValue,
                        page: pageValue
                    })
                };

                updateFromPage.value += 1;
            }
        });

        // 列表一进入就会触发setRow，所以保存搜索参数第一次不能触发
        let isFirstSetRow = true;
        const saveParamInPathInRow = ref(false);
        const setRow = (row: number, page: number) => {
            rowValue = row;
            pageValue = page;
            if (isNotUseRequestData(props.requestData)) {
                requestDataWithRow.value = false;
            } else {
                requestDataWithRow.value = {
                    url: props.requestData.url,
                    param: Object.assign(props.requestData.param, {
                        row,
                        page
                    })
                };

                updateFromPage.value += 1;
                if (props.isSaveParamInPathInRow) {
                    if (isFirstSetRow) isFirstSetRow = false;
                    else saveParamInPathInRow.value = true;
                }
            }
        };

        const actionEmit = (eventName: string, value: object) => {
            emit(eventName, value);
            emit('actionEmit', eventName, value);
        };

        // 组件外部获取总条数
        watch(totalCount, () => {
            emit('getTotal', totalCount.value);
        });

        return {
            totalCount,
            paginatParam,
            getSlotName,
            setRow,
            requestDataWithRow,
            updateFromPage,
            resetPagination,
            actionEmit,
            saveParamInPathInRow,
            backFirstPage
        };
    }
});
